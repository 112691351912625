<template>
  <Layout>
    <section class="csms-payment">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="price-plan-search-status">{{ $t('payment.priceStatus') }}</label>
                      <multiselect id="price-plan-search-status"
                                   v-model="searchForm.priceStatus"
                                   label="text" track-by="value"
                                   :options="statusOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3" v-if="$_app.user.roles[0] === 'ADMIN'">
                      <label class="form-label" for="price-plan-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="price-plan-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="price-plan-search-price-name">{{ $t('payment.priceName') }}</label>
                      <input type="text" class="form-control" id="price-plan-search-price-name"
                             v-model="searchForm.priceName" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         thead-class="align-middle"
                         show-empty :empty-text="$t('payment.noPrice')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <div v-if="data.column === 'vat'" class="d-flex flex-column">
                      <span>{{ $t('payment.vat') }}</span>
                      <span>(%)</span>
                    </div>
                    <div v-else-if="data.column === 'slowSellingPrice' || data.column === 'fastSellingPrice'">
                      <span>{{ $t(`payment.${data.column}`) }}</span>
                      <i class="mdi mdi-information-outline fs-5 align-middle ms-2" :id="`${data.column}Tooltip`"
                         style="position:relative; cursor:pointer;"/>
                      <b-popover :target="`${data.column}Tooltip`" triggers="hover">
                        <span class="pre-tag-tooltip">{{ $t('payment.vatIncluded') }}</span>
                      </b-popover>
                    </div>
                    <div v-else-if="!$_utils.isEmpty(data.label)">
                      <span>{{ $t(`payment.${data.column}`) }}</span>
                    </div>
                    <div v-if="data.column === 'slowSupplyPrice' || data.column === 'fastSupplyPrice' ||
                      data.column === 'slowSellingPrice' || data.column === 'fastSellingPrice'">
                      (¥/kWh)
                    </div>
                  </template>

                  <template #cell(priceStatus)="data">
                    <span :class="statusFormatter(data.item.priceStatus, 'color')">{{ statusFormatter(data.item.priceStatus, 'text') }}</span>
                  </template>
                  <template #cell(hqName)="data">
                    {{ data.item.hqBusiness ? data.item.hqBusiness.hqName : '-' }}
                  </template>
                  <template #cell(slowSupplyPrice)="data">
                    {{ data.item.slowSupplyPrice.toLocaleString() }}
                  </template>
                  <template #cell(fastSupplyPrice)="data">
                    {{ data.item.fastSupplyPrice.toLocaleString() }}
                  </template>
                  <template #cell(slowSellingPrice)="data">
                    {{ data.item.slowSellingPrice.toLocaleString() }}
                  </template>
                  <template #cell(fastSellingPrice)="data">
                    {{ data.item.fastSellingPrice.toLocaleString() }}
                  </template>
                  <template #cell(startDate)="data">
                    {{ $_formatter.timestampToDate(data.item.startDate) }}
                  </template>
                  <template #cell(endDate)="data">
                    {{ $_formatter.timestampToDate(data.item.endDate) }}
                  </template>

                  <template #cell(etc)="data" v-if="$_app.user.roles[0] !== 'CP'">
                    <b-button size="sm" :variant="data.item.priceStatus === 'Expired' ? 'outline-primary' : 'warning'"
                              @click="view(data.item.priceId, data.item.priceStatus)">
                      <i class="uil me-2" :class="data.item.priceStatus === 'Expired' ? 'uil-search-plus' : 'uil-edit'" />
                      <span>{{ data.item.priceStatus === 'Expired' ? $t('btn.view') : $t('btn.edit') }}</span>
                    </b-button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <!-- Bottom Button Start -->
              <template v-if="$_app.user.roles[0] !== 'CP'">
                <div class="row ion-mb-mb-100">
                  <div class="col-12 d-flex justify-content-end">
                    <b-button variant="success" @click="create">
                      <i class="uil uil-plus me-2"></i>
                      <span>{{ $t('btn.create') }}</span>
                    </b-button>
                  </div>
                </div>
              </template>
              <!-- Bottom Button End -->

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <create-modal ref="pricePlanCreateModal" @callback="getItems" />
      <edit-modal ref="pricePlanEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import CreateModal from '@/views/pages/payment/PricePlanCreateModal.vue';
import EditModal from '@/views/pages/payment/PricePlanEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, CreateModal, EditModal
  },
  data() {
    return {
      title: 'chargePrice',
      hqOptions: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        priceStatus: { text: i18n.t('common.total'), value: null },
        hqId: { text: i18n.t('common.total'), value: null },
        priceName: null,
      },
      statusOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('payment.expired'), value: 'Expired' },
        { text: i18n.t('payment.inUse'), value: 'InUse' },
        { text: i18n.t('payment.toBeUsed'), value: 'ToBeUsed' }
      ],
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'priceStatus', label: '상태', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'priceName', label: '요금제명', class: 'text-center' },
        { key: 'slowSupplyPrice', label: '완속 요금', class: 'text-center' },
        { key: 'fastSupplyPrice', label: '급속 요금', class: 'text-center' },
        { key: 'vat', label: '소비세율 (%)', class: 'text-center' },
        { key: 'slowSellingPrice', label: '완속 청구 금액', class: 'text-center' },
        { key: 'fastSellingPrice', label: '급속 청구 금액', class: 'text-center' },
        { key: 'startDate', label: '시작일', class: 'text-center' },
        { key: 'endDate', label: '종료일', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' }
      ],
      items: null,
      selectedId: null
    }
  },
  created() {
    const vm = this;

    if (this.$_app.user.roles[0] !== 'CP') {
      vm.fields.push({ key: 'etc', label: '', class: 'text-center' });
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/PaymentUnitPrice/list';
      vm.showOverlay();

      http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items
        if (this.$_app.user.email !== 'admin@i-on.net') {
          vm.items = [...vm.items].filter(ele => ele.priceId !== 10000000000334);
        }
        vm.pagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Payment Unit Price List Error :: ', err)
        vm.hideOverlay();
      });
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        priceStatus: { text: i18n.t('common.total'), value: null },
        hqId: { text: i18n.t('common.total'), value: null },
        priceName: null
      };
      vm.searchConditions = [];

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.priceStatus.value)) {
        vm.searchConditions.push({ searchKey: 'priceStatus', searchValue: vm.searchForm.priceStatus.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqName', searchValue: vm.searchForm.hqId.text });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.priceName)) {
        vm.searchConditions.push({ searchKey: 'priceName', searchValue: vm.searchForm.priceName });
      }

      vm.pagination.currentPage = 1;
      vm.getItems();
    },
    statusFormatter(value, type) {
      if (value === 'Expired') {
        return type === 'text' ? i18n.t('payment.expired') : 'text-danger';
      } else if (value === 'InUse') {
        return type === 'text' ? i18n.t('payment.inUse') : 'text-primary';
      } else if (value === 'ToBeUsed') {
        return type === 'text' ? i18n.t('payment.toBeUsed') : 'text-success';
      } else {
        return type === 'text' ? value : '';
      }
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getItems();
        vm.hideOverlay();
      }
    },
    create() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.pricePlanCreateModal.show();
      });
    },
    view(selectedId) {
      const vm = this;

      vm.selectedId = selectedId;

      vm.$nextTick(() => {
        this.$refs.pricePlanEditModal.show();
      });
    }
  }
}
</script>
